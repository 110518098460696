import * as React from "react"
import Layout from "../../components/Layout"
import { Row, Col } from "react-bootstrap"
import { Link } from "gatsby"

const IndexPage = () => {
    return (
        <Layout pageTitle="Colours and Fonts">
            <Row>
                <Col>
                    <Link to="../">Back</Link>
                </Col>
            </Row>
            <Row className="mt-7">
                <Col className="text-md-right">
                    <h2 className="display-4 font-weight-bold">
                        How to use
                        <br />
                        the colours
                        <br />
                        <span className="text-primary">effectively</span>
                    </h2>
                </Col>
                <Col>
                    <p className="para">
                        Dark blue is our primary background and text colour.
                        Pink is second in command. Use the orange, teal and
                        purple to add dynamic components.
                    </p>
                    <p className="para">
                        Where possible, keep the contract by putting the colour
                        next to the dark blue rather than next to another
                        colour.
                    </p>
                    <p className="para">
                        When putting text on top of any of the colours, make
                        sure to use the dark blue or white (depending on how
                        easy it is to read).
                    </p>
                    <p className="para">
                        Use the interface colours only to user interfaces where
                        these colours are universally recognised. For example,
                        red for error messages and green for successful
                        messages. They are not to be used as brand elements.
                    </p>
                </Col>
            </Row>
            <Row className="mt-5">
                <Col>
                    <h1>Brand Colors</h1>
                </Col>
            </Row>
            <Row className="mt-5">
                <ColourCol name="Pink" hex="#F8408C" bg="pink" />
                <ColourCol name="Orange" hex="#FBA02F" bg="orange" />
                <ColourCol name="Teal" hex="#2ADDC6" bg="teal" />
                <ColourCol name="Purple" hex="#AF32DD" bg="purple" />
                <ColourCol name="Dark Pink" hex="#DC007C" bg="dark-pink" />
                <ColourCol name="Dark Orange" hex="#F76408" bg="dark-orange" />
                <ColourCol name="Dark Teal" hex="#06BF99" bg="dark-teal" />
                <ColourCol name="Dark Purple" hex="#7809BF" bg="dark-purple" />
                <ColourCol name="Dark Blue" hex="#2C1B73" bg="dark-blue" />
            </Row>
            <Row className="mt-5">
                <Col>
                    <h1>User Interface Colours</h1>
                </Col>
            </Row>
            <Row className="mt-5">
                <ColourCol name="Green" hex="#18CE7B" bg="green" />
                <ColourCol name="Red" hex="#FF3261" bg="red" />
                <ColourCol name="Yellow" hex="#E2C231" bg="yellow" />
                <ColourCol name="Blue" hex="#5493FF" bg="blue" />
            </Row>
            <Row className="mt-5">
                <Col>
                    <h1>Fonts</h1>
                </Col>
            </Row>
            <Row className="mt-5">
                <Col className="text-md-right">
                    <h2 className="display-4 font-weight-bold">
                        How to use
                        <br />
                        typography
                        <br />
                        &amp; fonts
                        <br />
                        <span className="text-primary">effectively</span>
                    </h2>
                </Col>
                <Col>
                    <p className="para">
                        We want to have freedom to use text dynamically, making
                        sure it is easy to read whilst being interesting and
                        exciting to look at!
                    </p>
                    <p className="para">
                        Contrast is a key brand element, and we can use this
                        contrast in our text by utilising strong titles, colours
                        and dynamic layout - as seen here.
                    </p>
                    <p className="para">
                        This example shows the title right aligned, whilst the
                        body text is left aligned to meet in the middle. We can
                        then center this to the space available to create a
                        dynamic group of text.
                    </p>
                    <p className="para">
                        Only ever use Poppins for titles or headers, with
                        newsreader reserved for body text.
                    </p>
                </Col>
            </Row>
            <Row className="mt-5">
                <Col>
                    <h1 className="display-3">Main Title</h1>
                    <p className="lead">Poppins, SemiBold, Line Height 1.5</p>
                    <p className="para">
                        The main title text is the least frequently used, and
                        usually only for one title line.
                    </p>
                </Col>
            </Row>
            <Row className="mt-5">
                <Col>
                    <h1>Heading / HEADING</h1>
                    <p className="lead">Poppins, Bold, Line Height 1.15</p>
                </Col>
            </Row>
            <Row className="mt-5">
                <Col>
                    <p className="para text-primary">Normal Text</p>
                    <p className="para">Newsreader, Regular, Line Height 1.5</p>
                </Col>
            </Row>
        </Layout>
    )
}

const ColourCol = ({ name, hex, bg }) => {
    return (
        // <Row className='mt-5 colour-row'>
        <Col className="colour-col mt-4" md={3}>
            <div className={`colour-square bg-${bg}`}></div>
            <h3>{name}</h3>
            <h4>{hex}</h4>
        </Col>
        // </Row>
    )
}

export default IndexPage
